import detectBrowserLanguage from 'detect-browser-language';

const lengDefault = ()=>{
    if(detectBrowserLanguage().indexOf('es')!==-1){
        return {len:'es'}
    }
    else{
        return {len:'en'}
    }
}

export const reducer = (state = lengDefault(), action) =>{
    switch(action.type){
        case 'CHANGE_LENGUAGE':
            return {
                len: action.len
            }
        case 'RESET_LENGUAGE':
            return {     
                ...state,
                len: 'en'           
            }
        default:
            return state;
    }
}