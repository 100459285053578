import React from 'react';
import {Link} from 'react-router-dom';
import logo from '../../assets/brand.svg';

const Header = ({state,eventsActions}) => {

    const {len} = state;

    const handleChangeLenguage = (e) =>{
        e.preventDefault();
        if(len==='en'){
            eventsActions('CHANGE_LENGUAGE',{len:'es'});
        }
        else{
            eventsActions('CHANGE_LENGUAGE',{len:'en'});
        }
    }

    return ( 

        <nav className="navbar">
            <div className="container">
                <div className="nav-row">
                    <div className="brand">
                        <Link
                            to={'/'}
                            className="navbar-link"
                        >
                            <img src={logo} className="brand-image" alt="brand logo" />
                        </Link>
                    </div>

                    <div className="elements">
                        <ul className="navbar-list">
                            <li className="navbar-item">
                                <Link
                                    to={'/'}
                                    className="navbar-link"
                                >{len==='en'?'Home':'Inicio'}</Link>
                            </li>
                            <li className="navbar-item">
                                <Link
                                    to={'/blogposts'}
                                    className="navbar-link"
                                >Blog</Link>
                            </li>
                            <li className="navbar-item">
                                <Link
                                    to={'/merch'}
                                    className="navbar-link"
                                >{len==='en'?'Merch':'Merc'}</Link>
                            </li>
                            <li className="navbar-item">
                                <Link
                                    to={'/about'}
                                    className="navbar-link"
                                >{len==='en'?'About':'Acerca'}</Link>
                            </li>
                            <li className="navbar-item">
                                <a href="!#" className="navbar-link" onClick={handleChangeLenguage}>{len==='en'?'Spanish':'Inglés'}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

     );
}
 
export default Header;