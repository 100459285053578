import React,{useEffect,useState} from 'react';
import Firebase from '../../connection/tofirebase';
import Loading from '../Loading';
import BlogPostList from './BlogPostList';

const Blog = ({state,eventsActions}) => {
    const {len} = state;
    const [blogList, setBlogList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        Firebase.getAllBlogs().then(blogPosts=>{
            setBlogList([...blogPosts]);
            setLoading(false);
        })
    },[]);

    return ( 
            <div>
                {loading===true?
                (<Loading
                    len={len}
                />)
                :
                (
                    <div className="blog-container">
                        <BlogPostList
                            blogPostList={blogList}
                            len={len}
                        />

                    </div>
                )
                }
            </div>

     );
}
 
export default Blog;