import {connect} from 'react-redux';
import Home from './Home';

const getActualState = (state) =>(
    {
        state
    }
)

const actions = (dispatch) =>(
    {
        eventsActions: (type,payload) => dispatch({type,...payload})
    }
)

export default connect(getActualState,actions)(Home);