import React from 'react';
import style from './BlogPost.css';
import {useHistory} from 'react-router-dom';

const BlogCard = ({len,post}) => {

    const goTo =  useHistory();

    const handleViewPost = (e)=>{
        e.preventDefault();
        goTo.push(`/blogpost/${post.id}`);
    }

    return ( 
        <div className="six columns">
            <h5>{len==='en'?post.en.title:post.es.title}</h5>
            <div className="dropdown">
                <img alt={post.id} src={post.imageUrl} className="u-full-width" />
                <div className="card-pinguin">
                    <div className="text-section" onClick={handleViewPost}>
                        <p>{len==='en'?'View':'Ver'}</p>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default BlogCard;