import React from 'react';
import Style from './About.css';
import img1 from '../../assets/L1.png';
import img2 from '../../assets/L2.jpeg';
import img3 from '../../assets/L3.jpg';
import img4 from '../../assets/L4.png';

const About = ({state,eventsActions}) => {
    const {len} = state;

    return ( 
                <div className="about-container">
                    <h1 className="brand-h1">Parallels Development</h1>
                    <div className="container">
                        <img alt="v1" className="img1" src={img1} />
                    </div>
                    <div className="p-container">
                        <p className="bb">
                            {len==='en'?
                            ('In 2017, Parallels started as a concept, being its logo design; multiple planes in parallel, one above the other observed from a higher point. It has its origin in the classrooms of the University, when its founder was still a student of systems engineering.')
                            :
                            ('Parallels, en el año 2017, inicia como un concepto, siendo su diseño en logo; múltiples planos en paralelo, uno sobre otro observados desde un punto superior. Tiene su origen en las aulas de la Universidad, cuando su fundador era aún un estudiante de ingeniería en sistemas.')
                            }    
                        </p>
                    </div>
                    <div className="container">
                        <img alt="v2" className="img2" src={img2} />
                    </div>
                    <div className="p-container">
                        <p className="bb">
                            {len==='en'?
                            ('After having attended various workshops related to entrepreneurship and with his idea in mind, its founder Jesus Herrara, converts Parallels from a concept to a business idea dedicated to the development of software products.')
                            :
                            ('Luego de haber cursado diversos talleres relacionados al emprendimiento y con su idea en mente, su fundador Jesus Herrara, convierte a Parallels de un concepto, a una idea de empresa dedicada al desarrollo de productos de software.')    
                            }
                        </p>
                    </div>
                    <div className="container">
                        <img alt="v3" className="img3" src={img3} />
                    </div>
                    <div className="p-container">
                        <p className="bb">
                            {len==='en'?
                            ('Months later, Parallels began its first software development project, however its founder found that this was not enough, so he directed his energies to create a combination of passion and knowledge, something totally different and innovative; musical effects devices, better known as guitar pedals adding software development.')
                            :
                            ('Meses después Parallels, emprende su primer proyecto de desarrollo de software sin embargo su fundador encontró que eso no era suficiente, por lo cual orientó sus energías a crear una combinación entre pasión y conocimientos, algo totalmente diferente e innovador; dispositivos de efectos musical, más conocidos como pedales de guitarra adicionando el desarrollo de software.')
                            }
                        </p>
                    </div>
                    <div className="p-container">
                        <p className="bb">
                            {len==='en'?
                            ('This led Jesus to focus efforts on understanding the operation of different electronic components and designing pedal prototypes in parallel with software development.')
                            :
                            ('Esto llevo a Jesus, a focalizar esfuerzos en la comprensión del funcionamiento de los  diferentes componentes electrónicos y al diseño de prototipos de pedales paralelamente al desarrollo de software.')
                            }
                        </p>
                    </div>
                    <div className="p-container">
                        <p className="bb">
                            {len==='en'?
                            ('Today Parallels is a technology development company that provides products and services that will allow your creativity to go beyond the limits.')
                            :
                            ('Hoy en día Parallels es una empresa de desarrollo tecnológico que brinda productos y servicios que permitirán que tu creatividad vaya más allá de tus límites.')
                            }
                        </p>
                    </div>
                    <div className="container">
                        <img alt="v4" className="img4" src={img4} />
                    </div>
                    <h5>
                        {len==='en'?
                        ('"Creativity will always be the starting point of everything"')
                        :
                        ('“La creatividad siempre será el punto de partida de todo”')
                        }
                    </h5>
                    <h6 className="my-name">Jesus Herrera</h6>
                    <p className="bb txt-centered">{len==='en'?('Founder'):('Fundador')}</p>
                    <div className="p-container">
                        <p className="bb">{len==='en'?'Written by: ':'Escrito por: '}<span><a className="d-link" href="https://twitter.com/DEZFHn?s=20">Dania Zavala</a></span></p>
                    </div>
                </div>
     );
}
 
export default About;