import React from 'react';
import BlogCard from './BlogCard';

const BlogPostList = ({len,blogPostList}) => {
    return ( 
        <div className="blog-list-container">
            {blogPostList.map(blogPost=>(
                <BlogCard
                    key={blogPost.id}
                    post={blogPost}
                    len={len}
                />
            ))}
        </div>
     );
}
 
export default BlogPostList;