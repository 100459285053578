import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import {createStore} from 'redux';
import {reducer} from './reducer/Reducer';
import { ToastProvider } from 'react-toast-notifications';

import Header from './components/header/HeaderConnect';
import Footer from './components/footer/FooterConnect';
//change this pointers when implement redux from support en-es
import Home from './components/home/HomeConnect';
import Blog from './components/blog/BlogConnect';
import Merch from './components/merch/MerchConnect';
import About from './components/about/AboutConnect';
import BlogPost from './components/blogPost/BlogPostConnect';
import Contact from './components/contact/ContactConnect';

const store = createStore(reducer);

function App() {
  return (
            <Router>
              <Provider store={store}>
                <ToastProvider placement='bottom-left'>
                <Header/>
                <div className="container">
                  <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/blogposts" component={Blog}/>
                    <Route exact path="/merch" component={Merch}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/blogpost/:blogID" component={BlogPost}/>
                    <Route exact path="/askproduct/:productID" component={Contact}/>
                    <Route exact path="/contact" component={Contact}/>
                  </Switch>
                </div>
                <Footer/>
                </ToastProvider>
              </Provider>
            </Router>
  );
}

export default App;