import React from 'react';
import ProductCard from './ProductCard';
import style from './products.css';

const ProductList = ({merchList, len}) => {
    return ( 
        <div className="product-list-container">
                {
                    merchList.map(merch=>(
                        <ProductCard
                            key={merch.id}
                            merch={merch}
                            len={len}
                        />
                    ))
                }
            </div>
     );
}
 
export default ProductList;