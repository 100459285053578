import React,{useState,useEffect} from 'react';
import {useParams,useLocation} from 'react-router-dom';
import imageDefault from '../../assets/noProduct.png';
import { useToasts } from 'react-toast-notifications';
import Firebase from '../../connection/tofirebase';
import style from './Contact.css';
import moment from 'moment';

const Contact = ({len,eventsActions}) => {
    
    const {productID} = useParams();
    const {state} = useLocation();
    const { addToast } = useToasts();
    const [image, setImage] = useState('');
    const [requestObj, setRequestObj] = useState({
                                                    productID:'',
                                                    fullname:'',
                                                    email:'',
                                                    comment:''
                                                });
    const {fullname,email,comment} = requestObj;

    useEffect(()=>{
        if(!productID){
            console.log('No hace nada')
            setImage(imageDefault);
        }else{
            setRequestObj({...requestObj,productID:productID})
            setImage(state.imageUrl);
        }
    },[]);

    const handleChange = (e)=>{
        setRequestObj({
            ...requestObj,
            [e.target.name]:e.target.value
        })
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        const res=validations();
        if(res!==''){
            addToast(res, {
                appearance: 'error',
                autoDismiss: true,
            });
            return;
        }

        //send request and clear form.
        if(window.confirm(len==='en'?'Are you sure to send form?':'¿Está seguro de enviar formulario?')){
            Firebase.sendRequest(requestObj, moment().format('MMMM Do YYYY, h:mm:ss a') ).then(()=>{
                addToast(len==='en'?'Your request/comment sent succefully':'Su solicitud o comentario enviados exitosamente', {
                    appearance: 'success',
                    autoDismiss: true,
                  });
                  //clear form
                setRequestObj({
                    productID:'',
                    fullname:'',
                    email:'',
                    comment:''
                });
            }).catch(err=>{
                console.log(err.message);
                addToast(len==='en'?'An error occurred\ntry again later':'Ocurrió un error\nInténtalo de nuevo en unos minutos.', {
                    appearance: 'error',
                    autoDismiss: true,
                });
            });
        }
    }

    const validations= ()=>{
        const testEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

       if(fullname===''){
            return len==='en'?'All fields are required':'Todos los campos son obligatorios';     
       }
       if(email===''){
            return len==='en'?'All fields are required':'Todos los campos son obligatorios';
       }
       if(!testEmail.test(email)){
            return len==='en'?'Email format incorrect':'Formato de email no válido';
       }
       return '';
    }

    return ( 
            <div className="contact-container">
                <div className="row pinguin">
                <h5 className="contact-title">Fill the fields and we will send you an email with detailed information</h5>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <div className="one-half column">
                            <label>{len==='en'?'Full Name':'Nombre Completo'}</label>
                            <input
                                type="text"
                                name="fullname"
                                className="u-full-width"
                                placeholder={len==='en'?'Jimmy Hendrix':'Juan Perez'}
                                onChange={handleChange}
                                value={fullname}
                            />
                            <label>{len==='en'?'Email':'Correo'}</label>
                            <input
                                type="email"
                                name="email"
                                className="u-full-width"
                                placeholder={len==='en'?'yourEmail@domail.com':'tuCorreo@dominio.com'}
                                onChange={handleChange}
                                value={email}
                            />
                            <label>{len==='en'?'Comment(optional)':'Comentario(opcional)'}</label>
                            <textarea
                                className="u-full-width"
                                name="comment"
                                placeholder={len==='en'?'Your description here':'Tu descripción aquí'}
                                onChange={handleChange}
                                value={comment}
                            ></textarea>
                            <div className="static-data-container">
                                <label>{len==='en'?'Contact directly:':'Contacta directamente:'}</label>
                                <p className="wb ml-2">parallelsdev@gmail.com</p>
                            </div>
                        </div>
                        <div className="one-half column">
                            <label>{len==='en'?'Product':'Producto'}</label>
                            <span className="contact-image-container">
                                <img
                                    src={image}
                                    alt="ProductContactImage"
                                    className="contact-image"
                                />
                            </span>
                            <button type="submit" className="bordered-button u-full-width">{len==='en'?'Send':'Enviar'}</button>
                        </div>
                    </form>
                </div>
            </div> 
            );
}
 
export default Contact;