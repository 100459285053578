import React from 'react';
import {useHistory} from 'react-router-dom';

const LastBlogPost = ({len, lastBlogPost}) => {
    const goTo =  useHistory();

    const handleReadPost = (e)=>{
        e.preventDefault();
        goTo.push(`/blogpost/${lastBlogPost.id}`);
    }
    return ( 
            <div className="row">
                <div className="five columns">
                    <img alt="last post" src={lastBlogPost.imageUrl} className="u-full-width"/>
                </div>
                <div className="seven columns">
                    <h4>{len==='en'?lastBlogPost.en.title:lastBlogPost.es.title}</h4>
                    <p className="bb">
                        {len==='en'?
                            (lastBlogPost.en.sections[0].content0.length>500?lastBlogPost.en.sections[0].content0.substr(0,500)+'...':lastBlogPost.en.sections[0].content0)
                            :(lastBlogPost.es.sections[0].content0.length>500?lastBlogPost.es.sections[0].content0.substr(0,500)+'...':lastBlogPost.es.sections[0].content0)
                        }
                    </p>
                    <button 
                            className="bordered-button-inverse"
                            onClick={handleReadPost}
                    >
                        {len==='en'?"READ MORE!":"¡Lee mas!"}
                    </button>
                </div>
            </div>
        );
}
 
export default LastBlogPost;