import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyAO7exQ4LIp8MbNxPTHlZELX_QURakgaNI",
    authDomain: "parallelswebsite.firebaseapp.com",
    databaseURL: "https://parallelswebsite.firebaseio.com",
    projectId: "parallelswebsite",
    storageBucket: "parallelswebsite.appspot.com",
    messagingSenderId: "657671164866",
    appId: "1:657671164866:web:cc2d9ede7d6a3b66a97c5b",
    measurementId: "G-VYH87LDNH9"
  };

class Firebase {
    constructor() {
      app.initializeApp(config);
      this.app = app;
      this.auth = app.auth();
      this.db = app.firestore();
      this.storage = app.storage();
      this.storageRef = app.storage().ref();
    }

    /*getAuth = () =>{
        return this.auth;
    }

    doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();
    */

    //blogs
    getAllBlogs = () =>{
      const blogsArray = [];
      const blogsPosts = this.db.collection('blogPost');
      const blogs = blogsPosts.where('status','==','PUBLISHED').get().then(snapshot=>{
                      if(!snapshot){
                        return;
                      }
                      else{
                        snapshot.forEach(doc=>{
                          let obj = doc.data();
                          obj.id = doc.id;
                          blogsArray.push(obj);
                        })
                        return blogsArray;
                      }
                }).catch(err=>{
                  console.log(err.message);
                })
      return blogs
    }

    getBlogPost = (id) =>{
      const blogsPosts = this.db.collection('blogPost');
      const blogs = blogsPosts.doc(id).get().then(snapshot=>{
                      if(!snapshot){
                        return;
                      }
                      else{
                        return {...snapshot.data(),id:id};
                      }
                }).catch(err=>{
                  console.log(err.message);
                })
      return blogs;
    }

    updatePostViews = (id,newViews)=>{
      return  this.db.collection('blogPost').doc(id).update({views:newViews});
    }

    //Products

     getAllProducts = () =>{
      const productsArray = [];
      const productsPosts = this.db.collection('products');
      const products = productsPosts.where('status','==','PUBLISHED').get().then(snapshot=>{
                      if(!snapshot){
                        return;
                      }
                      else{
                        snapshot.forEach(doc=>{
                          let obj = doc.data();
                          obj.id = doc.id;
                          productsArray.push(obj);
                        })
                        return productsArray;
                      }
                }).catch(err=>{
                  console.log(err.message);
                })
      return products
    }

    //clients requests

    sendRequest = (requestObj, requestDate)=>{
      const requestCollection = this.db.collection('requests').doc();
            const pushRequest = requestCollection.set({
                                                    ...requestObj,
                                                        date:requestDate
                                                    });
            return pushRequest
    }

}

const firebase = new Firebase();

export default firebase;