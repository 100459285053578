import {connect} from 'react-redux';
import Contact from './Contact';

const getActualState = (state) =>(
    {
        len:state.len
    }
)

const actions = (dispatch) =>(
    {
        eventsActions: (type,payload) => dispatch({type,...payload})
    }
)

export default connect(getActualState,actions)(Contact);