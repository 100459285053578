import React,{useEffect, useState} from 'react';
import Firebase from '../../connection/tofirebase';
import Loading from '../Loading';
import ProductList from '../products/ProductList';

const Merch = ({state, eventsActions}) => {
    
    const {len} = state;
    const [productsList, setProductsList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        Firebase.getAllProducts().then(productsArray=>{
            setProductsList([...productsArray]);
            setLoading(false);
        })
    },[]);

    return ( 
        <div>
            {loading===true?
                (<Loading
                    len={len}
                />)
            :
                (<div className="merch-container">
                    <ProductList
                        merchList={productsList}
                        len={len}
                    />
                </div>)}
        </div>
     );
}
 
export default Merch;