import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = ({state,eventsActions}) => {
    
    const {len} = state;

    return ( 
        <Fragment>
        <footer className="footer">
            <div className="container">
                <div className="spacer">
                    <Link
                        to={'/blogposts'}
                        className="navbar-link"
                    >blog</Link>

                    <Link
                        to={'/merch'}
                        className="navbar-link"
                    >{len==='en'?'Merch':'Merc'}</Link>

                    <Link
                        to={'/about'}
                        className="navbar-link"
                    >{len==='en'?'About':'Acerca'}</Link>
                </div>
            </div>
            <div className="container">
                <div className="brands">
                    <a className='social-link' href='!#'>
                        <FontAwesomeIcon className="social-icons" icon={faFacebookF}  />
                    </a>
                    <a href='!#' className='social-link' >
                        <FontAwesomeIcon className="social-icons" icon={faInstagram}  />
                    </a>
                    <a href='!#' className='social-link' >
                        <FontAwesomeIcon className="social-icons" icon={faTwitter} />
                    </a>
                </div>
            </div>
            <div className="container">
                <h3 className="ft-slogan">MORE POWAH BABEH!</h3>
            </div>
            <div className="container">
                <div className="footer-leyend">
                    <p> <a href="https://instagram.com/ramirez.helen1609?igshid=nha0sc1qhdy6">Helen Ramirez</a> Designs | Parallels Development &copy; 2020</p>
                </div>
            </div>
        </footer>
        </Fragment>

     );
}
 
export default Footer;