import React,{useEffect,useState, Fragment} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import banner1 from '../../assets/banner1.png';
import banner2 from '../../assets/banner2.png';
import Firebase from '../../connection/tofirebase';
import ProductsList from '../products/ProductList';
import LastBlogPost from './LastBlogPost';
import Loading from '../Loading';
import moment from 'moment';

const Home = ({state,eventsActions}) => {

    const [productsList, setProductsList] = useState([]);
    const [lastBlogPost, setLastBlogPost] = useState({});
    const [loading, setLoading] = useState(true);

    const {len} = state;

    useEffect(()=>{
     Firebase.getAllProducts().then(products=>{
            const temArr = products.filter(product=>(product.main===true))
            setProductsList([temArr[0],temArr[1],temArr[2]]);
            Firebase.getAllBlogs().then(blogPosts=>{
                const blogsArray = blogPosts.sort((a,b)=>{return new Date(b.publishedDate) - new Date(a.publishedDate)});
                setLastBlogPost({...blogsArray[0]});
                setLoading(false);
            });
        });
    },[]);

    return (
        <Fragment>
            {loading===true? 
            (<Loading
                len={len}
            />)    
            :(<div className="home-container">
                <Carousel>
                    <div>
                        <img alt='More Power Babeh!' src={banner1}/>
                    </div>
                    <div>
                        <img alt='We talk about that united us' src={banner2}/>
                    </div>
                </Carousel>
                <div className="products-section">
                    <h3>{len==='en'?'Some Products':'Nuestros Productos'}</h3>
                    <ProductsList
                        merchList={productsList}
                        len={len}
                    />
                </div>
                <div className='last-post-section'>
                    <h3>{len==='en'?'Last Post':'último post'}</h3>
                    <LastBlogPost
                        len={len}
                        lastBlogPost={lastBlogPost}
                    />
                </div>

            </div>)}
        </Fragment>
      );
}
 
export default Home;