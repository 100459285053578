import React from 'react';
import logo from '../logo.png';
import '../App.css';

const Loading = ({len}) => {
    return ( 
        <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>{len==='en'?'Loading...':'Cargando...'}</h1>
        </header>
      </div>
     );
}
 
export default Loading;