import React from 'react';

const Sections = ({subtitle,content}) => {
    return ( 
            <div>
                <h5>{subtitle}</h5>
                <p className="bb">{content}</p>
            </div> 
            );
}
 
export default Sections;