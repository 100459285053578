import React,{useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Firebase from '../../connection/tofirebase';
import Loading from '../Loading';
import Sections from './Sections';
import moment from 'moment'

const BlogPost = ({len,eventsActions}) => {

    const {blogID} = useParams();
    const [blogPost, setBlogPost] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        loadPost();
    },[]);

    const loadPost = ()=>{
        Firebase.getBlogPost(blogID).then(rblogPost=>{
            const newView = rblogPost.views + 1;
            setBlogPost({...rblogPost});
            setLoading(false);
            Firebase.updatePostViews(blogID,newView);
        });
    }


    return (
            <div> 
            {loading===true?
            (<Loading
                len={len}
            />)    
            :(<div className="blog-post-container">
                <h1>{len==='en'?blogPost.en.title:blogPost.es.title}</h1>
                {len==='en'?(blogPost.en.sections.map((section,id)=>(
                    <Sections
                        key={id}
                        subtitle={section['subtitle'+id]}
                        content={section['content'+id]}
                    />
                ))):
                (blogPost.es.sections.map((section,id)=>(
                    <Sections
                        key={id}
                        subtitle={section['subtitle'+id]}
                        content={section['content'+id]}
                    />
                )))
                }
                <h6>{len==='en'?'Author: '+blogPost.en.author:'Autor: '+blogPost.es.author}</h6>
                <h6>{moment(blogPost.publishedDate).format('MMMM Do YYYY, h:mm:ss a')}</h6>
            </div>)
            }
            </div> 
            );
}
 
export default BlogPost;