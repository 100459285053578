import React,{useState} from 'react';
import Modal from 'react-modal';
import {useHistory} from 'react-router-dom';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius          :  '0px',
      border                : '0px',
      maxWidth              : '944px',
      maxHeight             : '600px'
    }
  };

  Modal.setAppElement(document.getElementById('root'));
  Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.5)';

const ProductCard = ({merch,len}) => {
    
    const {id,en,es} = merch;
    const goTo =  useHistory();

    const [modalIsOpen,setIsOpen] = useState(false);

    const handleModal = (e)=>{
        e.preventDefault();
        setIsOpen(!modalIsOpen);
    }

    const handleAskForProduct = (e)=>{
        e.preventDefault();
        goTo.push(`/askproduct/${id}`,{en,es,imageUrl:merch.imageUrl});
    }


    return ( <div className='four columns'>
    <div className='product-separator'>
        <div className='product-card'>
            <div className='u-max-full-width'>
                <img
                    alt={id+'product'}
                    src={merch.imageUrl}
                    className='u-max-full-width'
            />
            </div>
            <h5 className='product-name'>{len==='en'?en.name:es.name}</h5>
            <p className='wb product-description'>
                {len==='en'?
                    (merch.en.description.length>297?merch.en.description.substr(0,287) + '...':merch.en.description):
                    (merch.es.description.length>297?merch.es.description.substr(0,287) + '...':merch.es.description)
                }
            </p>
            <div className='product-buttons'>
                <button
                    onClick={handleModal} 
                    className="bordered-button u-max-full-width"
                    >{len==='en'?'MORE!':'¡VER MAS!'}</button>
            </div>
        </div>
    </div>
    <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleModal}
          style={customStyles}
          contentLabel="Produc Modal"
        >
            <span className="modal-image-container">
                <img
                    alt={merch.id}
                    src={merch.imageUrl}
                    className='modal-image'
                />
            </span>
            <h3 className='product-modal-title'>{len==='en'?en.name:es.name}</h3>
            <p className="wb"><strong>{len==='en'?'Description:':'Descripción:'}</strong></p>
            <p className='wb product-description'>
                {len==='en'?
                    (merch.en.description):
                    (merch.es.description)
                }
            </p>
            <p className="wb"><strong>{len==='en'?'Specs:':'Características:'}</strong></p>
            <ul className='modal-product-specs'>
                {len==='en'?
                (
                    merch.en.specs.map(spec=>(
                        <li key={spec.id}>{spec['spec'+spec.id] +' : '+spec['value'+spec.id]}</li>
                    ))
                ):(
                    merch.es.specs.map(spec=>(
                        <li key={spec.id}>{spec['spec'+spec.id] +' : '+spec['value'+spec.id]}</li>
                    ))
                )}
            </ul>
            <div className="button-family mt0">
                            <button onClick={handleAskForProduct} className="bordered-button">
                                {len==='en'?'request':'solicitar'}
                            </button>
                            <button onClick={handleModal} className="filled-button">
                                {len==='en'?'Close':'Cerrar'}
                            </button>
            </div>
    </Modal>
</div> );
}
 
export default ProductCard;